import { generateRandomKey } from './utilities';

$(document).on('turbolinks:load', function () {

  var userSelectallOrgs = $('#user-selectall-orgs');
  if (userSelectallOrgs.length) {
    userSelectallOrgs.click(function() {
      if(this.checked) {
        $('input[id^="user_organization_ids_"]').each(function(){
          this.checked = true;
        });
      } else {
        $('input[id^="user_organization_ids_"]').each(function(){
          this.checked = false;
        });
      } 
    });
  }

  var userSelectallOrgs = $('#user-selectall-locs');
  if (userSelectallOrgs.length) {
    userSelectallOrgs.click(function() {
      if(this.checked) {
        $('input[id^="user_location_ids_"]').each(function(){
          this.checked = true;
        });
      } else {
        $('input[id^="user_location_ids_"]').each(function(){
          this.checked = false;
        });
      } 
    });
  }

  var userPassword = $('#user_password');
  var userPasswordConfirmation = $('#user_password_confirmation');
  var userPasswordGenerateBtn = $('#user_generate_random_password_button');
  if (userPassword.length && userPasswordConfirmation.length && userPasswordGenerateBtn.length) {
    userPasswordGenerateBtn.on("click", function (e) {
      e.preventDefault();
      var randomKey = generateRandomKey();
      userPassword.val(randomKey);
      userPassword.prop('type','text');
      userPasswordConfirmation.val(randomKey);
      userPasswordConfirmation.prop('type','text');
    });
  } 
});