export function generateRandomKey() {
  const lowerCaseChars = 'abcdefghijklmnopqrstuvwxyz';
  const upperCaseChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const numericChars = '0123456789';
  const specialChars = '!@#$%^&*()_+';
  const allChars = lowerCaseChars + upperCaseChars + numericChars + specialChars;
  let keyArray = [];

  keyArray.push(lowerCaseChars[Math.floor(Math.random() * lowerCaseChars.length)]);
  keyArray.push(upperCaseChars[Math.floor(Math.random() * upperCaseChars.length)]);
  keyArray.push(numericChars[Math.floor(Math.random() * numericChars.length)]);
  keyArray.push(specialChars[Math.floor(Math.random() * specialChars.length)]);

  for (let i = keyArray.length; i < 16; i++) {
      keyArray.push(allChars[Math.floor(Math.random() * allChars.length)]);
  }
  for (let i = keyArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [keyArray[i], keyArray[j]] = [keyArray[j], keyArray[i]];
  }
  return keyArray.join('');
}